<template>
  <v-app>
    <!-- <Navbar v-show="getStatusLogin"/> -->

    <router-view />

    <v-footer color="primary" dark app>
      <span class="white--text" style="width: 100%;text-align: center"
        >Copyright © {{ new Date().getFullYear() }} - Software Development II, Indomaret Group
        v1.3.0</span
      >
    </v-footer>
  </v-app>
</template>

<script>
// import { Sidebar } from "@/components/Sidebar.vue";
// import Navbar from "./components/Navbar";
export default {
  name: "App",

  component: {
    // Sidebar,
    // Navbar,
  },

  // data() {
  //   return {
  //     $baseurl1: "http://localhost/",
  //     $baseurl2: "http://116.204.128.50:80/",
  //     $baseurl: this.$baseurl2,
  //   };
  // },
  // computed: {
  //       getStatusLogin: function () {
  //           return this.$store.state.isLogin;
  //       }
  //   }
};
</script>

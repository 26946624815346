<template>
  <div>
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Input Realisasi Vaksinasi Covid-19 Untuk Diri Sendiri
              </h3>
              <template>
                <v-row justify="center">
                  <v-dialog v-model="dialog" persistent max-width="600px">
                    <template>
                      <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                        Open Dialog
                      </v-btn> -->
                    </template>
                    <v-card>
                      <v-card-title>
                        <span class="headline">Penjadwalan Ulang</span>
                      </v-card-title>
                      <v-card-text>
                        <v-container>
                          <v-row>
                            <v-col cols="12">
                              <h2
                                class="subtitle-1 font-weight-regular text-justify"
                              >
                                Pilih Opsi:
                              </h2>

                              <v-radio-group
                                v-model="m_jadwalulang"
                                required
                                row
                              >
                                <v-radio
                                  v-for="item in itemJadwalUlang"
                                  :key="item.id"
                                  :label="`${item.name}`"
                                  :value="item.id"
                                ></v-radio>
                              </v-radio-group>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="dialog = false"
                        >
                          Close
                        </v-btn>
                        <v-btn
                          color="blue darken-1"
                          text
                          @click="submitJadwalUlang()"
                          v-show="m_jadwalulang"
                        >
                          Save
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>
              <v-form class="pa-4 mx-3" ref="form">
                <div class="my-2">
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div>
                        <v-text-field
                          v-model="nama"
                          label="Nama"
                          :rules="namaRules"
                          required
                          readonly
                        ></v-text-field>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div>
                        <v-text-field
                          v-model="ktp"
                          label="KTP"
                          :rules="ktpRules"
                          required
                          readonly
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="nik"
                        :counter="10"
                        label="NIK"
                        :rules="nikRules"
                        required
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="phoneNumber"
                        label="No. Handphone"
                        :rules="noHpRules"
                        required
                        readonly
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="nama_atasan"
                        label="Nama Atasan"
                        :rules="namaAtasanRules"
                        required
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="nik_atasan"
                        :counter="10"
                        label="NIK Atasan"
                        :rules="nikAtasanRules"
                        required
                        readonly
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" sm="6">
                      <div>
                        <v-text-field
                          v-model="phoneNumber_atasan"
                          label="No. Handphone Atasan"
                          :rules="noHpAtasanRules"
                          required
                          readonly
                        ></v-text-field>
                      </div>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="12" sm="6">
                      <!-- <h2 class="subtitle-1 font-weight-regular text-justify">
                        Tanggal Vaksinasi:
                      </h2> -->
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="computedDateFormatted"
                            prepend-icon="mdi-calendar"
                            label="Pilih Tanggal Realisasi Vaksinasi"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            :rules="tglRules"
                          ></v-text-field>
                        </template>
                        <v-date-picker v-show="this.vaksinasiKe == '1'"
                          v-model="date"
                          :max="nowDate"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                        <v-date-picker v-show="this.vaksinasiKe > '1'"
                          v-model="date"
                          :max="nowDate"
                          :min="tglVaksin1"
                          no-title
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                      <!-- <v-date-picker v-model="picker"></v-date-picker> -->
                    </v-col>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-text-field
                        label="Realisasi Vaksinasi Ke:"
                        v-model="vaksinasiKe"
                        required
                        readonly
                        :maxlength="1"
                        :counter="1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="d-flex" cols="12" sm="6">
                      <v-select
                        v-show="this.vaksinasiKe == '1'"
                        :items="itemsVaksin"
                        item-text="NAMA"
                        item-value="ID_VAKSIN"
                        label="Jenis Vaksinasi"
                        v-model="jenisvaksinasi"
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                      </v-select>
                      <v-select
                        v-show="this.vaksinasiKe > '1' && this.hasil == 'Outstanding'"
                        readonly
                        :items="itemsVaksin"
                        item-text="NAMA"
                        item-value="ID_VAKSIN"
                        v-model="jenisvaksinasi"
                        label="Jenis Vaksinasi"
                        required
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                      </v-select>
                      <v-select
                        v-show="this.vaksinasiKe > '1' && this.hasil == 'Success'"
                        :items="itemsVaksin"
                        item-text="NAMA"
                        item-value="ID_VAKSIN"
                        v-model="jenisvaksinasi"
                        label="Jenis Vaksinasi"
                        required
                      >
                        <template slot="selection" slot-scope="data">
                          <!-- HTML that describe how select should render selected items -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                        <template slot="item" slot-scope="data">
                          <!-- HTML that describe how select should render items when the select is open -->
                          {{ data.item.NAMA }} -
                          {{ data.item.FLAG_VAKSIN }}
                        </template>
                      </v-select>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-show="this.vaksinasiKe == '1'"
                        v-model="idsertifikasi"
                        label="ID Sertifikat"
                        required
                        :rules="rulesSertifikasi"
                        :counter="24"
                        :maxlength="24"
                      ></v-text-field>
                      <v-text-field
                        v-show="this.vaksinasiKe > '1'"
                        readonly
                        v-model="idsertifikasi"
                        label="ID Sertifikat"
                        required
                        :rules="rulesSertifikasi"
                        :counter="24"
                        :maxlength="24"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-file-input
                        :rules="rulesUpload"
                        accept="image/png, image/jpeg, image/bmp"
                        prepend-icon="mdi-camera"
                        label="Upload Bukti Pendukung"
                        show-size
                        ref="fileInput"
                        v-model="files"
                      ></v-file-input>
                      <div class="caption red--text">*Ukuran Max 1 MB</div>
                      <div class="caption">Format: png/jpeg/bmp</div>
                      <div class="caption">
                        Gambar yang diupload adalah screenshot Sertifikat
                        Vaksinasi yang didapat dari
                        <a href="https://pedulilindungi.id/" target="_blank"
                          >pedulilindungi.id</a
                        >
                        (lihat contoh di bawah)
                      </div>
                      <v-img
                        src="image/contoh_sertifikat.jpeg"
                        max-height="300px"
                        max-width="300px"
                      ></v-img>
                    </v-col>

                    <!-- <v-col cols="12" sm="6" v-show="JawabanHasilVaksin == '2'">
                      <v-textarea
                        name="input-7-1"
                        v-model="alasan"
                        label="Alasan"
                        value=""
                        required
                        :rules="rulesAlasan"
                        :counter="100"
                      ></v-textarea>
                    </v-col> -->
                  </v-row>
                  <v-row>
                    <!-- <v-row v-show="JawabanHasilVaksin == '1'">
                    
                    
                  </v-row> -->
                  </v-row>
                  <v-row justify="center">
                    <v-col cols="12" sm="2" class="shrink text-end">
                      <v-btn
                        color="primary"
                        block
                        :loading="btnLoading"
                        v-show="validBerhasil"
                        @click="submit()"
                        >submit</v-btn
                      >
                    </v-col>
                  </v-row>
                </div>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },

  mounted() {
    // this.user=this.$store.state.user;
    
    this.nama = sessionStorage.getItem("nama");
    this.nik = sessionStorage.getItem("nik");
    this.ktp = sessionStorage.getItem("ktp");
    this.phoneNumber = sessionStorage.getItem("nohp");
    this.nik_atasan = sessionStorage.getItem("nik_atasan");
    this.nama_atasan = sessionStorage.getItem("nama_atasan");
    this.phoneNumber_atasan = sessionStorage.getItem("nohp_atasan");
    
    // this.cobaGambar();
    // console.log(sessionStorage.getItem("parent_organization_id"));
    
    // if (localStorage.getItem("reloaded")) {
    //   // The page was just reloaded. Clear the value from local storage
    //   // so that it will reload the next time this page is visited.
    //   localStorage.removeItem("reloaded");
    //   this.overlay = false;
    // } else {
    //   // Set a flag so that we know not to reload the page twice.
    //   localStorage.setItem("reloaded", "1");
    //   location.reload();
     
    // }
    // this.itemVaksin();
    this.vaksinKe();
    
    // console.log(this.vaksinasiKe);
    // this.setDrawer(false);
    // this.Role = this.user.ROLE
  },

  computed: {
    ...mapGetters({
      user: "user",
      hasAllAccess: "hasAllAccess",
      // getDrawer: "drawer",
    }),

    drawer: {
      get() {
        return this.getDrawer;
      },
      set(value) {
        this.setDrawer(value);
      },
    },

    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    validBerhasil() {
      return (
        this.date != null &&
        this.idsertifikasi != "" &&
        // this.idsertifikasi.length <= 26 &&
        this.idsertifikasi.length == 24 &&
        this.jenisvaksinasi != null &&
        this.realisasi != null &&
        // this.JawabanHasilVaksin == "1" &&
        this.files != null &&
        this.files.size <= 1000000 
        // this.files.type == "image/png"
      );
    },

    // validGagal() {
    //   return (
    //     this.date != null &&
    //     // this.jenisvaksinasi != null &&
    //     this.realisasi != null &&
    //     this.alasan != "" &&
    //     this.alasan.length <= 100 &&
    //     this.JawabanHasilVaksin == "2" &&
    //     this.files != null &&
    //     this.files.size <= 1000000
    //   );
    // },
  },

  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    // max: 26,
    nowDate: new Date().toISOString().substr(0,10),
    tglVaksin1: "",
    btnLoading: false,
    nik: "",
    ktp: "",
    nama: "",
    phoneNumber: "",
    nama_atasan: "",
    nik_atasan: "",
    phoneNumber_atasan: "",
    JawabanHasilVaksin: "",
    realisasi: "",
    idsertifikasi: "",
    alasan: "",
    files: null,
    jenisvaksinasi: null,
    date: null,
    m_jadwalulang: "",
    jadwalulang: "",
    vaksinasiKe: "",
    hasil: "",
    flag: "",
    tesGambar: "",
    // addRealisasiNone: "",
    dialog: false,
    overlay: true,
    nikRules: [
      (v) => !!v || "NIK harus diisi",
      (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    namaRules: [(v) => !!v || "Nama harus diisi"],
    ktpRules: [(v) => !!v || "No KTP harus diisi"],
    noHpRules: [(v) => !!v || "No HP harus diisi"],
    namaAtasanRules: [(v) => !!v || "Nama Atasan HP harus diisi"],
    nikAtasanRules: [
      (v) => !!v || "NIK Atasan HP harus diisi",
      // (v) => (v && v.length == 10) || "NIK Harus 10 Karakter",
    ],
    noHpAtasanRules: [(v) => !!v || "No HP Atasan harus diisi"],
    tglRules: [(v) => !!v || "Tanggal harus diisi"],
    hasilRules: [(v) => !!v || "Rencana vaksin harus diisi"],
    rulesUpload: [
      (v) => !v || v.size <= 1000000 || "Ukuran file maks 1 MB!",
      (v) => !!v || "File harus diisi",
      (v) => !v || v.type == "image/png" || v.type == "image/jpeg" || v.type == "image/bmp" || "File harus png/jpeg/bmp",
    ],
    rulesSertifikasi: [
      (v) => !!v || "ID Sertifikat harus diisi",
      // (v) => !v || v.length <= 26 || "Jumlah Karakter Melebihi 26!",
      (v) => !v || v.length == 24 || "ID Sertifikasi Harus 24 Karakter!",
      // (v) => !v || /(?=.*[A-Za-z0-9])/.test(v) || "Harus Mengandung Huruf dan Angka",
    ],
    rulesAlasan: [
      (v) => !!v || "Alasan harus diisi",
      (v) => !v || v.length <= 100 || "Jumlah Karakter Melebihi 100!",
    ],
    menu: false,
    modal: false,
    menu2: false,
    valid: true,
    itemsVaksin: [],
    itemJawab: [
      {
        id: "1",
        name: "Berhasil",
      },
      {
        id: "2",
        name: "Gagal",
      },
    ],
    // itemRealisasi: [
    //   {
    //     id: "1",
    //     name: "1",
    //   },
    //   {
    //     id: "2",
    //     name: "2",
    //   },
    // ],

    itemRealisasi: [],

    itemJadwalUlang: [
      {
        id: "None",
        name: "Jadwal Ulang",
      },
      {
        id: "Failed",
        name: "Tidak Jadwal Ulang",
      },
    ],
  }),

  methods: {
    reloadPage(){
      if (localStorage.getItem("reloaded")) {
      // The page was just reloaded. Clear the value from local storage
      // so that it will reload the next time this page is visited.
        localStorage.removeItem("reloaded");
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem("reloaded", "1");
        location.reload();
      }
    },

    submit() {
      // console.log(this.nik);
      // console.log(this.ktp);
      // console.log(this.nama);
      // console.log(this.phoneNumber);
      // console.log(this.nik_atasan);
      // console.log(this.nama_atasan);
      // console.log(this.phoneNumber_atasan);
      // console.log(this.JawabanHasilVaksin);
      // console.log(this.date);
      // console.log(this.idsertifikasi);
      // console.log(this.jenisvaksinasi);
      // console.log(this.alasan);
      console.log(this.files);

      this.$swal({
        icon: "info",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menambahkan Realisasi Vaksin",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Yes`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.btnLoading = true;
          // const addRealisasi = {
          //   code: "1",
          //   data: {
          //     nik: this.nik,
          //     nama: this.nama,
          //     ktp: this.ktp,
          //     no_hp: this.phoneNumber,
          //     hasil: this.JawabanHasilVaksin,
          //     vaksinasi_ke: this.realisasi,
          //     vaksin: this.jenisvaksinasi,
          //     tanggal: this.date,
          //     nomor_sertifikat: this.idsertifikasi,
          //     alasan: "",
          //     file_path: null,
          //     nik_atasan: this.nik_atasan,
          //     nama_atasan: this.nama_atasan,
          //     no_hp_atasan: this.phoneNumber_atasan,
          //   },
          // };
          const cabang = sessionStorage.getItem("cabang");
          const unit = sessionStorage.getItem("pt");
          const bagian = sessionStorage.getItem("bagian");
          const nama_pt = sessionStorage.getItem("nama_pt");
          const nama_cabang = sessionStorage.getItem("nama_cabang");
          const nama_bagian = sessionStorage.getItem("nama_bagian");
          const jobclass = sessionStorage.getItem("job_class");
          const parent_organization_id = sessionStorage.getItem(
            "parent_organization_id"
          );
          const parent_organization = sessionStorage.getItem(
            "parent_organization"
          );
          const grandparent_organization_id = sessionStorage.getItem(
            "grandparent_organization_id"
          );
          const grandparent_organization = sessionStorage.getItem(
            "grandparent_organization"
          );
          if (this.files.size <= 1000000) {
            var reader = new FileReader();
            reader.readAsDataURL(this.files);
            // var test = "";
            reader.onload = () => {
              var hasil = reader.result;
              var array = hasil.split(",");
              // console.log("tes 1 " + array[0]);
              // console.log("tes 2 " + array[1]);
              this.addRealisasi = {
                code: "1",
                data: {
                  // nik: this.nik,
                  // nama: this.nama,
                  // ktp: this.ktp,
                  // no_hp: this.phoneNumber,
                  // hasil: this.JawabanHasilVaksin,
                  // vaksinasi_ke: this.realisasi,
                  // vaksin: this.jenisvaksinasi,
                  // tanggal: this.date,
                  // alasan: this.alasan,
                  // file_path: array[1],
                  // nik_atasan: this.nik_atasan,
                  // nama_atasan: this.nama_atasan,
                  // no_hp_atasan: this.phoneNumber_atasan,

                  nik: this.nik,
                  nama: this.nama,
                  ktp: this.ktp,
                  no_hp: this.phoneNumber,
                  hasil: "1",
                  vaksinasi_ke: this.vaksinasiKe,
                  vaksin: this.jenisvaksinasi,
                  tanggal: this.date,
                  nomor_sertifikat: this.idsertifikasi,
                  alasan: "",
                  file_path: array[1],
                  nik_atasan: this.nik_atasan,
                  nama_atasan: this.nama_atasan,
                  no_hp_atasan: this.phoneNumber_atasan,
                  cabang: cabang,
                  unit: unit,
                  departemen: bagian,
                  nama_pt: nama_pt,
                  nama_cabang: nama_cabang,
                  nama_bagian: nama_bagian,
                  jobclass: jobclass,
                  parent_organization_id: parent_organization_id == null ? null : parent_organization_id,
                  parent_organization: parent_organization == null ? null : parent_organization,
                  grandparent_organization_id: grandparent_organization_id == null ? null : grandparent_organization_id,
                  grandparent_organization: grandparent_organization == null ? null : grandparent_organization,
                },
              };
              console.log(this.addRealisasi);
              // const varToken = sessionStorage.getItem("token");
              // const headers = {
              //   JWT: varToken,
              //   // NIK: sessionStorage.getItem("nik"),
              // };
              const axios = require("axios");
              axios
                .post(
                  this.$baseurl + "RealisasiVaksinasi",
                  this.addRealisasi,
                  { withCredentials: true }
                )
                .then((response) => {
                  // console.log(response.data);
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.btnLoading = false;
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });
                  } else if (response.data.Result.message == "OK") {
                    this.btnLoading = false;
                    this.$swal({
                      icon: "success",
                      title: "Berhasil",
                      text: "Realisasi Vaksinasi Telah Ditambahkan",
                    }).then(() => {
                      // this.initialize();
                      this.clearForm();
                    });
                  } else {
                    this.btnLoading = false;
                    this.$swal({
                      icon: "error",
                      title: "Maaf!",
                      text:
                        "Gagal Menambahkan, " + response.data.Result.message,
                    });
                  }
                })
                .catch((error) => {
                  this.btnLoading = false;
                  console.log(error);
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Menambahkan",
                  });
                });
            };
          } else {
            this.btnLoading = false;
            this.$swal({
              icon: "error",
              title: "Maaf!",
              text: "Ukuran File Melebihi 1 MB",
            });
          }
        } else {
          this.btnLoading = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Realisasi Vaksin tidak tersimpan",
          });
        }
      });
      // this.$refs.fileInput.value = null;
      // console.log(this.$refs.fileInput.value)

      // this.$swal({
      //       icon: "success",
      //       title: "Berhasil!",
      //       text: "Data Rencana Vaksinasi Covid-19 berhasil disimpan",
      //     });
    },

    onKeyDown(evt) {
      if (this.realisasi.length <= 26) {
        if (evt.keyCode >= 48 && evt.keyCode <= 90) {
          evt.preventDefault();
          return;
        }
      }
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    itemVaksin() {
      const axios = require("axios");
      const dataVaksin = {
        code: "5",
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      axios
        .post(this.$baseurl + "MasterVaksin", dataVaksin, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Failed') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: response.data.Message,
            });
          } 
          // else if(response.data.Message == 'Token Requireds !!!!'){
          //   this.$swal({
          //       icon: "info",
          //       title: "Info!",
          //       text: "Session Anda Telah Habis",
          //     }).then(() => {
          //       sessionStorage.clear();
          //       this.$router.push({ name: "Login" });
          //     });
          // }
          else {
            this.itemsVaksin = response.data.message;
            // console.log(response.data.message);
          }

          // response.data.message.map(function(Nama, key) {
          //   this.itemsVaksin.push(Nama);
          // });
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Items Vaksin");
        });
    },

    vaksinWajib() {
      const axios = require("axios");
      const dataVaksin = {
        code: "7",
      };
      axios
        .post(this.$baseurl + "MasterVaksin", dataVaksin, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Failed') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: response.data.Message,
            });
          } 
          else {
            this.itemsVaksin = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Items Vaksin");
        });
    },

    vaksinBooster() {
      const axios = require("axios");
      const dataVaksin = {
        code: "8",
      };
      axios
        .post(this.$baseurl + "MasterVaksin", dataVaksin, { withCredentials: true })
        .then((response) => {
          if (response.data.Message == 'Failed') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: response.data.Message,
            });
          } 
          else {
            this.itemsVaksin = response.data.message;
          }
        })
        .catch((error) => {
          console.log(error);
          alert("Tidak Dapat Mengambil Items Vaksin");
        });
    },

    clearGagal() {
      this.date = null;
      this.alasan = "";
      this.files = null;
    },

    clearBerhasil() {
      this.date = null;
      if (this.vaksinasiKe == "1") {
        this.idsertifikasi = "";
      }

      this.jenisvaksinasi = null;
      this.files = null;
    },

    clearForm() {
      window.location.reload();
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },

    dataRealisasi() {
      const getRealisasi = {
        code: "2",
        data: {
          ID_Vaksin: this.jenisvaksinasi,
        },
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterVaksin", getRealisasi, { withCredentials: true })
        .then((response) => {
          // console.log(response.data.message[0].JUMLAH_VAKSINASI);
          var cars = response.data.message[0].JUMLAH_VAKSINASI;
          // var text = "";
          var i;
          var arr = [];
          var obj = {};
          for (i = 0; i < cars; i++) {
            // text += i + 1;
            obj = {
              id: i + 1,
              name: i + 1,
            };
            arr.push(obj);
          }
          // console.log(arr)
          this.itemRealisasi = arr;
        })
        .catch((error) => {
          console.log(error);
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Vaksinasi Ke- Tidak Ditemukan",
          });
        });
    },

    vaksinKe() {
      const dataVaksinKe = {
        code: "3",
        data: {
          nik: sessionStorage.getItem("nik"),
        },
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterRealisasi", dataVaksinKe, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
            this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            let hasil = response.data.message.message;
            this.vaksinasiKe = hasil;
            if(this.vaksinasiKe == "1"){
              this.vaksinWajib();
              this.overlay = false;
            }
            else if (this.vaksinasiKe > "1") {
              const hasilVaksinKe = {
                code: "4",
                data: {
                  nik: sessionStorage.getItem("nik"),
                },
              };
              // const varToken = sessionStorage.getItem("token");
              // const headers = {
              //   JWT: varToken,
              //   // NIK: sessionStorage.getItem("nik"),
              // };
              const axios = require("axios");
              axios
                .post(
                  this.$baseurl + "MasterRealisasi",
                  hasilVaksinKe,
                  {
                    withCredentials: true,
                  }
                )
                .then((response) => {
                  if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                    this.$swal({
                      icon: "info",
                      title: "Info!",
                      text: "Session Anda Telah Habis",
                    }).then(() => {
                      sessionStorage.clear();
                      this.$router.push({ name: "Login" });
                    });

                  } else {
                    const hasil = response.data.message[0]
                    this.hasil = hasil.HASIL;
                    this.flag = hasil.FLAG_VAKSIN;

                    if(hasil.HASIL == "Outstanding" && hasil.FLAG_VAKSIN == "Wajib"){
                      this.vaksinWajib();
                      this.jenisvaksinasi = hasil.ID_VAKSIN;

                    }else if(hasil.HASIL == "Success" && hasil.FLAG_VAKSIN == "Wajib"){
                      this.vaksinBooster();
                    }

                    this.idsertifikasi = hasil.NOMOR_SERTIFIKAT;
                    let myFutureDate = new Date(hasil.TANGGAL_VAKSINASI);
                    let valueDate = myFutureDate.setDate(myFutureDate.getDate()+ 1)
                    this.tglVaksin1 = new Date(valueDate).toISOString().substr(0, 10);
                    this.overlay = false;
                    // location.reload();
                    this.reloadPage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                  this.overlay = false;
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    // text: "Tidak Dapat Mengambil Vaksinasi Ke catch1",
                    text: error,
                  });
                });
            }else{
              this.overlay = false;
            }
            // console.log(vaksinasiKe);
          }
          // this.reloadPage();
        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
          this.$swal({
            icon: "error",
            title: "Maaf!",
            text: "Tidak Dapat Mengambil Vaksinasi Ke catch2",
          });
        });
    },

    lastStatus(){
      
    }

    // cobaGambar(){
    //   const coba = {
    //     code: "9",

    //   };
    //   const varToken = sessionStorage.getItem("token");
    //   const headers = {
    //     JWT: varToken,
    //     NIK: sessionStorage.getItem("nik"),
    //   };
    //   const axios = require("axios");
    //   axios
    //     .post(this.$baseurl + "api/v1/RencanaVaksinasi", coba, {
    //       headers,
    //     })
    //     .then((response) => {
    //       if (response.data.Message) {
    //         this.$swal({
    //           icon: "info",
    //           title: "Info!",
    //           text: "Session Anda Telah Habis",
    //         }).then(() => {
    //           sessionStorage.clear();
    //           this.$router.push({ name: "Login" });
    //         });
    //       } else {

    //         console.log(response.data.Result)
    //         this.tesGambar = response.data.Result;

    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.$swal({
    //         icon: "error",
    //         title: "Maaf!",
    //         text: "Tidak Dapat Mengambil Vaksinasi Ke",
    //       });
    //     });
    // }
  },
};
</script>

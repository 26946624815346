<template>
  <div>
    <Sidebar />
    <Toolbar />
    <v-main>
      <!-- <text>Coba</text> -->
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <v-card class="mx-auto elevation-5 py-4 px-2">
              <v-row justify="center">
                <v-img
                  src="image/indomaret.png"
                  aspect-ratio="3"
                  max-width="250"
                >
                </v-img>
              </v-row>
              <h3 class="text-center my-4">
                Master Jenis Vaksin
              </h3>

              <div class="pa-1 mx-4">
                <v-data-table
                  :headers="headers"
                  :items="itemsTableVaksin"
                  :search="search"
                  sort-by="nama"
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-dialog v-model="dialog" max-width="600px">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            New Vaksin
                          </v-btn>
                        </template>
                        <v-card>
                          <v-form ref="form">
                            <v-card-title>
                              <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.NAMA"
                                      label="Nama Vaksin"
                                      :rules="rulesNama"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-text-field
                                      v-model="editedItem.JUMLAH_VAKSINASI"
                                      label="Jumlah Pemberian"
                                      v-on:keypress="isNumber($event)"
                                      :counter="2"
                                      :maxlength="2"
                                      :rules="rulesJmlVaksin"
                                    ></v-text-field>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12" sm="6" md="6">
                                    <v-select
                                      v-model="editedItem.FLAG_VAKSIN"
                                      :items="items_flag"
                                      label="Flag Vaksin"
                                      item-text="FLAG_VAKSIN"
                                      item-value="FLAG_VAKSIN"
                                    ></v-select>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="close">
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                                v-show="valid"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-form>
                        </v-card>
                      </v-dialog>

                      <!-- jarak judul -->
                      <v-divider class="mx-4" inset vertical></v-divider>
                      <v-spacer></v-spacer>

                      <!-- search -->
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                    <v-icon small @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </template>
                  <!-- <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">
                      Refresh
                    </v-btn>
                  </template> -->
                </v-data-table>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Toolbar from "@/components/Toolbar.vue";
const axios = require("axios");

export default {
  name: "Home",
  components: {
    Sidebar,
    Toolbar,
  },
  data: () => ({
    // $baseurl: "http://localhost/",
    // $baseurl: "http://116.204.128.50:80/",
    // $baseurl: "https://ms.hrindomaret.com/",
    dialog: false,
    dialogDelete: false,
    search: null,
    headers: [
      // {
      //   text: "No",
      //   align: "start",
      //   sortable: false,
      //   value: "name",
      // },
      { text: "Nama Vaksin", value: "NAMA" },
      { text: "Flag Vaksin", value: "FLAG_VAKSIN" },
      { text: "Jumlah Pemberian", value: "JUMLAH_VAKSINASI" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    itemsTableVaksin: [],
    items_flag: [{ FLAG_VAKSIN: 'Wajib'},{ FLAG_VAKSIN: 'Booster'}],
    rulesNama: [(v) => !!v || "Nama harus diisi"],
    rulesJmlVaksin: [
      (v) => !v || v.length <= 2 || "Jumlah Melebihi 2 Karakter!",
      (v) => !!v || "Jumlah harus diisi",
    ],
    editedIndex: -1,
    editedItem: {
      Nama: "",
      Jumlah_Vaksinasi: "",
      Flag_Vaksin:"",
    },
    defaultItem: {
      Nama: "",
      Jumlah_Vaksinasi: "",
      Flag_Vaksin:"",
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Vaksin" : "Edit Vaksin";
    },
    valid() {
      let nama = this.editedItem.NAMA;
      let jml = this.editedItem.JUMLAH_VAKSINASI;
      let flag = this.editedItem.FLAG_VAKSIN;
      return (
        // this.rencana != "" &&
        // this.jenisvaksinasi != "" &&
        nama != null && jml != null && jml.length <= 2 && flag != null
        // this.editedItem.JUMLAH_VAKSINASI != "" &&
        // this.editedItem.JUMLAH_VAKSINASI.length <= 2
      );
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      const code = {
        code: 5,
      };
      // const varToken = sessionStorage.getItem("token");
      // const headers = {
      //   JWT: varToken,
      //   // NIK: sessionStorage.getItem("nik"),
      // };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterVaksin", code, { withCredentials: true })
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (result.Message == 'Token Requireds !!!!' || result.Message == 'Token & Signature Invalid !!!!') {
           this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            // console.log(result.message);
            this.itemsTableVaksin = result.message;
            // this.flagVaksin();
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    flagVaksin() {
      const code = {
        code: 6,
      };
      const axios = require("axios");
      axios
        .post(this.$baseurl + "MasterVaksin", code, { withCredentials: true })
        .then((response) => {
          // this.btnLoading = false;
          const result = response.data;
          if (result.Message == 'Token Requireds !!!!' || result.Message == 'Token & Signature Invalid !!!!') {
           this.$swal({
              icon: "info",
              title: "Info!",
              text: "Session Anda Telah Habis",
            }).then(() => {
              sessionStorage.clear();
              this.$router.push({ name: "Login" });
            });
          } else {
            this.items_flag = result.message;
          }
        })
        .catch((e) => {
          if (e.res && e.res.status === 400) {
            this.error_message = e.res.data.error;
            // this.snackbar=true;
          }
        });
    },

    isLetter(e) {
      let char = String.fromCharCode(e.keyCode); // Get the character
      if (/^[A-Za-z ]+$/.test(char)) return true;
      // Match with regex
      else e.preventDefault(); // If not match, don't add to input text
    },

    isNumber(n) {
      let char = String.fromCharCode(n.keyCode); // Get the character
      if (/^[0-9]+$/.test(char)) return true;
      // Match with regex
      else n.preventDefault(); // If not match, don't add to input text
    },

    editItem(item) {
      this.editedIndex = this.itemsTableVaksin.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
      console.log(this.editedItem);
    },

    deleteItem(item) {
      this.$swal({
        icon: "warning",
        title: "Apakah Anda Yakin?",
        text: "Untuk Menghapus Vaksin",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: `Cancel`,
        confirmButtonText: `Delete`,
      }).then((response) => {
        if (response.isConfirmed) {
          this.editedIndex = this.itemsTableVaksin.indexOf(item);
          this.editedItem = Object.assign({}, item);
          // console.log(this.editedItem);
          const deleteVaksin = {
            code: "4",
            data: {
              ID_Vaksin: this.editedItem.ID_VAKSIN,
            },
          };
          // const varToken = sessionStorage.getItem("token");
          // const headers = {
          //   JWT: varToken,
          //   // NIK: sessionStorage.getItem("nik"),
          // };
          axios
            .post(this.$baseurl + "MasterVaksin", deleteVaksin, {
              withCredentials: true,
            })
            .then((response) => {
              // console.log(response.data.message.message);
              if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                this.$swal({
                  icon: "info",
                  title: "Info!",
                  text: "Session Anda Telah Habis",
                }).then(() => {
                  sessionStorage.clear();
                  this.$router.push({ name: "Login" });
                });
              } else if (response.data.message.message == "OK") {
                this.$swal({
                  icon: "success",
                  title: "Berhasil",
                  text: "Vaksin Telah Dihapus",
                });
                this.initialize();
              } else {
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: response.data.message.message,
                });
              }
            })
            .catch((error) => {
              console.log(error);
              this.$swal({
                icon: "error",
                title: "Maaf!",
                text: "Gagal Menghapus",
              });
            });
        }
      });
    },

    deleteItemConfirm() {
      this.itemsTableVaksin.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      // this.$refs.form.resetValidation()
      this.$refs.form.reset();
      this.editedItem.NAMA = null;
      this.editedItem.JUMLAH_VAKSINASI = null;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    onlyNumber($event) {
      //console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      const ID_Vaksin = this.editedItem.ID_VAKSIN;
      const Nama = this.editedItem.NAMA;
      const Jumlah_Vaksinasi = this.editedItem.JUMLAH_VAKSINASI;
      const Flag_Vaksin = this.editedItem.FLAG_VAKSIN;
      const NIK = sessionStorage.getItem("nik");
      console.log(this.editedItem);
      if (this.editedIndex > -1) {
        // edit
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Mengubah Vaksin",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const editVaksinasi = {
              code: "3",
              data: {
                ID_Vaksin: ID_Vaksin,
                Nama: Nama,
                Jumlah_Vaksinasi: Jumlah_Vaksinasi,
                Flag_Vaksin: Flag_Vaksin,
                Creator_editor: NIK,
              },
            };
            // console.log(editVaksinasi);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   JWT: varToken,
            //   // NIK: sessionStorage.getItem("nik"),
            // };
            const axios = require("axios");
            axios
              .post(this.$baseurl + "MasterVaksin", editVaksinasi, {
                withCredentials: true,
              })
              .then((response) => {
                // console.log(response.data.message.message);
                if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.message.message == "OK") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: "Vaksin Telah Diubah",
                  });
                  this.initialize();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: "Gagal Mengubah",
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Mengubah",
                });
              });
          }
        });
      } else {
        // add new
        // this.desserts.push(this.editedItem);
        this.$swal({
          icon: "info",
          title: "Apakah Anda Yakin?",
          text: "Untuk Menambah Vaksin",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: `Cancel`,
          confirmButtonText: `Yes`,
        }).then((response) => {
          if (response.isConfirmed) {
            const addVaksinasi = {
              code: "1",
              data: {
                Nama: Nama,
                Jumlah_Vaksinasi: Jumlah_Vaksinasi,
                Flag_Vaksin: Flag_Vaksin,
                Creator_editor: NIK,
              },
            };
            // console.log(editVaksinasi);
            // const varToken = sessionStorage.getItem("token");
            // const headers = {
            //   JWT: varToken,
            //   // NIK: sessionStorage.getItem("nik"),
            // };
            const axios = require("axios");
            axios
              .post(this.$baseurl + "MasterVaksin", addVaksinasi, {
                withCredentials: true,
              })
              .then((response) => {
                console.log(response.data);
                if (response.data.Message == 'Token Requireds !!!!' || response.data.Message == 'Token & Signature Invalid !!!!') {
                  this.$swal({
                    icon: "info",
                    title: "Info!",
                    text: "Session Anda Telah Habis",
                  }).then(() => {
                    sessionStorage.clear();
                    this.$router.push({ name: "Login" });
                  });
                } else if (response.data.message.message == "OK") {
                  this.$swal({
                    icon: "success",
                    title: "Berhasil",
                    text: "Vaksin Telah Ditambahkan",
                  });
                  this.initialize();
                } else {
                  this.$swal({
                    icon: "error",
                    title: "Maaf!",
                    text: response.data.message.message,
                  });
                }
              })
              .catch((error) => {
                console.log(error);
                this.$swal({
                  icon: "error",
                  title: "Maaf!",
                  text: "Gagal Menambahkan",
                });
              });
          }
        });
      }
      this.close();
    },
  },
};
</script>
